try { // Import webpack jQ
  window.$ = window.jQuery = require('jquery')
} catch (e) {}

require('./img-zoomer') // Import external files

$(function(){
  // Hack to get the REAL window size
  $('body, html').css('overflow', 'hidden').css('overflow', 'visible')

  $('#form-mail').keyup(function(){
    if ($(this).is(':invalid')) {
      $(this).parents('.form-block').addClass('invalid')
    } else {
      $(this).parents('.form-block').removeClass('invalid')
    }
  })

  // Header

  $('.mobile-nav-button').click(function(){
    $('.header nav').slideToggle()
    $(this).toggleClass('active')
  })

  $(window).resize(function(){
    if ($(window).width() >= 996) {
      $('.header nav').attr('style', '')
      $('.mobile-nav-button').removeClass('active')
    }
  })

  // Hide dropdown when we clicked outside

  $(document).mouseup(function(e){
    if ($(window).width() < 996) {
      var menu = $('.mobile-nav-button.active')
      if (! menu.is(e.target) && menu.has(e.target).length === 0){
        menu.click()
      }
    }
  })
})
